<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="80%"
    top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="videoData"
        :rules="ruleValidate">
        <el-form-item label="视频名称" prop="name">
          <el-input v-model="videoData.name" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <!--        <el-form-item label="种类" prop="categoryId">-->
        <!--          <el-input v-model="videoData.categoryId" :placeholder="$t('common.pleaseEnter')"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="首页是否展示" prop="showOrder">
          <el-select v-model="videoData.showOrder" style="width: 50%">
            <el-option label="展示" :value="20"></el-option>
            <el-option label="不展示" :value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="videoData.status">
            <el-option label="待发布" :value="10"></el-option>
            <el-option label="发布" :value="20"></el-option>
            <el-option label="作废" :value="99"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面">
          <vm-upload-file
            v-model="videoData.coverPath"
            :filename.sync="videoData.coverName"
            accept=".img,.png,.jpg">
          </vm-upload-file>
        </el-form-item>
        <el-form-item label="视频">
          <vm-upload-file
            v-model="videoData.filePath"
            :filename.sync="videoData.name"
            accept=".mp4">
          </vm-upload-file>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="videoData.description" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  // import FaultSelect from "../fault/FaultSelect.vue";
  import VmUploadFile from "@/components/VmUploadFile";

  const moduleName = "videoDatas";

  export default {
    components: {VmUploadFile},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        videoData: {
          id: null,
          name: "",
          categoryId: "",
          status: 20,
          showOrder: 10,
          description: "",
          coverPath: "",
          coverName: "",
          filePath: "",
        },
        ruleValidate: {
          name: [
            {required: true, message: "视频名称不能为空", trigger: "blur"},
          ],
          filePath: [
            {
              required: true,
              message: "请上传视频",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {
      title() {
        return this.videoData.id === 0 ? this.$t("common.add") : this.$t("common.edit");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.videoData.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http.get("videoDatas/" + this.videoData.id).then(res => {
          console.log(res);
          this.videoData = Object.assign(this.videoData, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.videoData = {};
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("videoDatas", this.videoData).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.videoData.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
